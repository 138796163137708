<template>
  <div class="page ExportRecord">
    <div class="search">
      <a-range-picker />
      <a-button type="primary" style="margin-left: 20px">
        <template #icon><SearchOutlined /></template>
        搜索
      </a-button>
    </div>
    <a-table
      :dataSource="list"
      :columns="columns"
      :pagination="{
        current: page,
        total: total,
        pageSize: pageSize,
        showSizeChanger: true,
        showTotal: (total) => `总共 ${total} 条`,
        pageSizeOptions: ['20', '50', '100'],
      }"
      rowKey="id"
      @change="pageChange"
    >
      <template #add_time="{ record }">
        {{ formatDate(record.add_time) }}
      </template>
    </a-table>
  </div>
</template>

<script>
import { SearchOutlined } from "@ant-design/icons-vue";
import { reactive, toRefs } from "@vue/reactivity";
import { exportRecord } from "../../service/bigdata";
import { onMounted } from "@vue/runtime-core";
import { formatDate } from "../../utils/function";
const data = [
  {
    key: "1",
    name: "1",
    age: "18824673470",
    address: "王二麻子",
    name2: "深圳市华雅泰信息科技有限公司",
    age2: "官方网站(http://www.huayatai.com/contactus/)",
    address2: "2021-12-15 14:21:44",
  },
  {
    key: "2",
    name: "2",
    age: "18824673470",
    address: "王二麻子",
    name2: "深圳市华雅泰信息科技有限公司",
    age2: "官方网站(http://www.huayatai.com/contactus/)",
    address2: "2021-12-15 14:21:44",
  },
  {
    key: "3",
    name: "3",
    age: "18824673470",
    address: "王二麻子",
    name2: "深圳市华雅泰信息科技有限公司",
    age2: "官方网站(http://www.huayatai.com/contactus/)",
    address2: "2021-12-15 14:21:44",
  },
];
const columns = [
  {
    title: "序号",
    dataIndex: "id",
  },
  {
    title: "号码",
    dataIndex: "number",
  },
  {
    title: "姓名",
    dataIndex: "name",
  },
  {
    title: "公司名",
    dataIndex: "company_name",
  },
  {
    title: "来源",
    dataIndex: "phone_source",
    width: "50%",
     ellipsis: true,
  },
  {
    title: "时间",
    dataIndex: "add_time",
    slots: {
      customRender: "add_time",
    },
  },
];
export default {
  name: "ExportRecord",
  components: {
    SearchOutlined,
  },
  setup() {
    const state = reactive({
      total: undefined,
      page: 1,
      pageSize: 20,
      list: [],
    });
    onMounted(() => {
      init();
    });
    const init = async () => {
      const res = await exportRecord({
        access_token: sessionStorage.getItem("token"),
        page: state.page,
        limit: state.pageSize,
      });
      console.log(res);
      const { list, total } = res.data.data;
      state.list = list;
      state.total = total;
    };
    const pageChange = (e) => {
      state.page = e.current;
      state.pageSize = e.pageSize;
      init();
      // console.log(e);
    };
    return { data, columns, ...toRefs(state), pageChange, formatDate };
  },
};
</script>

<style scoped>
.search {
  margin-bottom: 15px;
}
.ExportRecord {
  padding: 10px;
  overflow: auto;
}
</style>